* {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
}
////FONTS
$fontRoboto: "Roboto", sans-serif;
$fontMain: #fff;
$fontColorSecond: #2e4765;
////
$border: #707070;
////COLOR PALETTE
$colors: (
  primary: #3c3b54,
  secondary: #43425d,
  third: #a3a0fb,
  pureWhite: #fff,
  pureBlack: #000,
  warn: #f44336,
  white: #fff,
);
/////////////// ICONS
$icons: "Material Icons";
$iconsSize: 24px;
$iconsPaddingNav: 0 5px;
$iconsColor: #a3a0fb;
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

$colorActive: #6666c4;
$hoverColorManage: #929191;
////////////// ICONS END
////////////// SIDENAV
$companyNameHeight: 70px;
$companyNameSpacing: 2px;
$sidenavListItemSpacingBetweenIcons: 0 5px;
$sidenavListItemHeight: 53px;
$sidenavWidth: 181px;
$sidenavWidthSmall: 60px;
$pictureHeight: 38px;
$pictureWidth: 38px;
$horizontalLine: 33px;
$topnavHeight: 70px;
$buttonSize: 18px;
$buttonWidth: 150px;
$buttonPaddingL: 10%;
$greenButton: #84be79;
$topnavWidthBreakPoint1: 1652px;
$navFillScontent: 1633px;
$whiteColor: #fff;
$iconWarn: #f8bb86;
$requiredFields: #ff0000;
$cancelButton: #d6d6d6;
$textColor: #888;
@mixin topnavBreakPonit {
  @media (max-width: #{$navFillScontent}) {
    @content;
  }
}

//TOPNAV BREAK POINTS
// $topnavWidthBreakPoint1: 1445px;
// $topnavWidthBreakPoint2: 1195px;
// $topnavWidthBreakPoint3: 800px;
// @mixin topnavWidthBreakPoint1 {
//     @media (max-width: #{$topnavWidthBreakPoint1}) {
//         @content;
//     }
// }
// @mixin topnavWidthBreakPoint2 {
//     @media (max-width: #{$topnavWidthBreakPoint2}) {
//         @content;
//     }
// }
// @mixin topnavWidthBreakPoint3 {
//     @media (max-width: #{$topnavWidthBreakPoint2}) {
//         @content;
//     }
// }
/////////////// END SIDENAV
/////////////// CONTENT BOX
$contentColor: #f0f0f7;
////////////// END CONTENT BOX
$headerSize: 70px;
$fontSizeHeader: 26px;
$fontSizePath: 14px;
$fontSizeGlobalSearch: 14px;
///////////////// TABLE COMPONENT
$paddingHeaderTop: 7px;
$paddingHeaderBottom: 80px;
$tableWidthLoads: 1286px;
$tablePaddingLeft: 20px;
$tableMarginRight: 20px;
$tableHeaderFontSize: 11px;
$tableFontSizeLoads: 12px;
$tableHeaderFontWeight: bold;
//$tableHeaderTextColor: #a3a6b4;
$tableHeaderTextColor: #5f6368;
$colorFontTabs: #3c4043;
$buttonHover: #e0e0e4;
$tableCellHeight: 51px;
$tableCellFontSize: 14px;
$tableCellDistance: 30px;
$tableHeaderColor: #e8eaec;
$tableCellColor: #202124;
$checkTrue: #84e0be;
$checkFalse: #ffbc6e;
$SolverAndMeasurments: 1875px;
$backgroundWhite: #ffffff;
$borderInfoText: 1px solid #dfe3eb;
// Solver and Acquistion ALIGN
@mixin SandM {
  @media (max-width: #{$SolverAndMeasurments}) {
    @content;
  }
}
@mixin SandMrightNav {
  @media (min-width: #{$SolverAndMeasurments}) {
    @content;
  }
}
//////////////
///////////////// MEASUREMENTS COMPOENENT
$paddingHeaderTopMeasurements: 20px;
$paddingHeaderBottomMeasurements: 80px;
$headerWidth: 170px;
$cardWidth: 1400px;
$cardHeight: 710px;
$cardMarginBottom: 20px;
$cardMarginLeft: 50px;
$cardBorderColor: 1px solid #707070;
$cardBorderRadius: 10px;
$cardTitleFontSize: 24px;
$cardSubTitleFontSize: 16px;
$cardPositionGap: 40px;
$dropdownColorMeas: lightgray;
$dropdownColorWeight: bold;
$cardButtonPositionMeasurements: #84e0be;
$cardButtonPositionMeasurementsBorder: 1px solid #9ac4e3;
$cardButtonPositionMeasurmentsDontHave: #ffbc6e;
$cardButtonOngoing: #ffbc6e;
$cardButtonCompleted: #00bf78;
$cardButtonReady: #9fd4fd;
$cardButtonBorderMeas: 1px solid #00bf78;
$cardButtonBorderRadiusMeas: 12px;
$cardIconColor: gray;
$cardDateFontSize: 16px;
$pagiationPaddingTop: 20px;
$pagiationColor: #3b86ff;
$breakPointTitle: 1414px;
@mixin breakPointTitle {
  @media (max-width: #{$breakPointTitle}) {
    @content;
  }
}
///////////////// END MEASUREMENTS COMPOENENT
//////////////// ACQUISITION

//$fontAcquisition: #4d4f5c;
$borderAcqusition: 1px solid #f1f1f3;
$fontSizeButton: 10px;
$buttonBorderRadius: 40px;
$buttonHeightAcq: 20px;
$buttonWidthAcq: 80px;
$checkTrueAqc: #84e0be;
$checkFalseAcq: #ffbc6e;
$fontColorDateAcq: #808495;
$dateAcqFontSize: 14px;
$nameAcqFontSize: 13px;
$dataAcqFontSize: 17px;
$dataAcqFontWeight: 900;
////////////  RESPONSIVE TABLE IN ACQUISITON
$scrollBarHeight: 945px;
// FOR LATER CONTENT FIT
//  $scrollBarWidth2: 1065x;
//  $scrollBarWidth1: 1486px;
$scrollBarWidth: 1408px;
@mixin scrollBarHeight {
  @media (max-height: #{$scrollBarHeight}) {
    @content;
  }
}
@mixin scrollBarWidth {
  @media (max-width: #{$scrollBarWidth}) {
    @content;
  }
}
// FOR LATER CONTENT FIT
// @mixin scrollBarWidth1 {
//     @media (max-width: #{$scrollBarWidth1}) {
//         @content;
//     }
// }
// @mixin scrollBarWidth2 {
//     @media (max-width: #{$scrollBarWidth2}) {
//         @content;
//     }
// }
//////////// END RESPONSIVE TABLE IN ACQUISITON
//////////// END ACQUISITION
//////////// SOLVER COMPONENT (DATA PROCESSING)
$fontColorDateSolver: #808495;
$fontSizeDateSolver: 14px;
$fontSizeVersion: 13px;
//$timeColorSolver: #4d4f5c;
$titleSolverFontSize: 17px;
$titleSolver: 900;
$borderSolver: 1px solid #f1f1f3;
$fontColor1Solver: #4d565c;
//////////// END SOLVER COMPONENT

///////// PROJECTS
$tableWidth: 1286px;
$tableHeaderFontSizeProjects: 13px;
$tableHeaderFontWeightProjects: bold;
$tableHeadingPaddingLeft: 8px;
$tableCellFontSizeProjects: 51px;
$tableCellFontWeightProjects: bold;
$tableCellHeaderGap: 8px;
$tableCellContentGap: 50px;
$tableCellContentGapLoads: 40px;
$paddingTopTitleProjects: 20px;
$paddingBottomTitleProjects: 80px;
$dropDownColor: lightgray;
$dropDownWeight: bold;
///////// END PROJECTS
//////// DETAILS
$paddingTopTitleDetails: 7px;
$paddingBottomTitleDetails: 80px;
$tabWidthfontSizePathBreakPoint: 1490px;
$buttonHeightDetails: 50px;
$buttonWidthDetails: 185px;
$buttonFontSizeDetails: 18px;
$buttonRadiusDetails: 5px;
$underLineColorBorder: 3px solid #3b86ff;
$underLineColor: #3b86ff;
$btnBreakPoint: 1110px;
@mixin tabWidthBreakPoint {
  @media (max-width: #{$btnBreakPoint}) {
    @content;
  }
}
@mixin btnBreakPoint {
  @media (max-width: #{$btnBreakPoint}) {
    @content;
  }
}
////////END DETAILS
///// SIGN IN / SIGN UP
$loginBreakPoint: 1300px;
@mixin loginBreakPoint {
  @media (max-width: #{$loginBreakPoint}) {
    @content;
  }
}
///// END SIGN IN / SIGN UP

/////Loads - Stop address
$stopaddressBackground: #d9edf7;
$material-icons: #0365f8;
$commentColor: #167afd;
$mat-cellBorderBottom: 1px solid rgba(0, 0, 0, 0.12);
$mat-form-field-wrapper: #7b7d86;
$hoverRow: #ededf3;

/////NewLoad
$StarColor: red;

/////Buttons:
$buttonSaveNewLoad: #1de9d6;
$buttonAddStopAddress: #8fc1fa;

/////RepolaceLoadsDokument-PDF:
$border_custom_file_upload: 1px solid #3843df;
$background_custom_file_upload: #3843df;
$color_custom_file_upload: whitesmoke;
$hoverBorder: 1px solid #3843df;
$hoverBackground: #bfc2ee;
$hoverColor: #3843df;
$pdfview_buttonBackground: #2427769e;
$pdfview_buttonColor: whitesmoke;
$pdfclose_buttonBackground: red;
$table-thead-thBorder: 1px solid #d1d0d0;
$backgroundSelectedNavItem: rgba(192, 188, 188, 0.1);
$hoverBackButton: #f0f0f0;

//Warning colors:
$yellowWarning: yellow;
$colorYellowWarning: #7b7d86;
$redWarning: red;
$orangeWarning: #ff6600;
